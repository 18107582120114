<html lang="en">

<head>
    <style>
        *,
        :after,
        :before {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box
        }

        a,
        b,
        body,
        div,
        h1,
        h2,
        h3,
        html,
        img,
        li,
        ol,
        p,
        span,
        ul {
            border: 0;
            font: inherit;
            margin: 0;
            padding: 0
        }

        html {
            font-size: 16px;
            line-height: 2em
        }

        body {
            background: #f5f5f5;
            color: #333;
            cursor: default;
            font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            line-height: 2;
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none
        }

        a,
        div.wpembed-index a,
        div.wpembed-index ol {
            color: #06c
        }

        h1,
        h2,
        h3 {
            font-weight: 700;
            line-height: 1.2;
            margin: 0 0 1em
        }

        .wpembed-madewith,
        .wpembed-nav,
        ol,
        p,
        ul {
            margin-bottom: 1.5em
        }

        h1 {
            font-size: 2em
        }

        h2 {
            font-size: 1.5em
        }

        h3 {
            font-size: 1.375em
        }

        a {
            outline: 0;
            text-decoration: none
        }

        .wpembed-nav-button:hover,
        a:hover {
            text-decoration: underline
        }

        a[href*="https://www.copyrighted.com"],
        a[href*="https://www.websitepolicies.com"] {
            color: #333
        }

        ol,
        ul {
            margin-left: 1.5em
        }

        ul {
            list-style: disc
        }

        .hidden {
            display: none
        }

        .wpembed-wrapper {
            display: flex;
            flex-direction: column;
            min-height: 100vh
        }

        .wpembed-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            background: #fff;
            margin-right: auto;
            margin-left: auto;
            padding: 1.5em 1.5em 0;
            width: 100%
        }

        @media (min-width:768px) {
            body {
                font-size: 1.062em;
                line-height: 2em
            }

            .wpembed-container {
                padding: 2em 2em .5em
            }
        }

        @media (min-width:1200px) {
            body {
                font-size: 1.125em;
                line-height: 2em
            }

            .wpembed-container {
                padding: 3em 3em 1.5em;
                width: 1170px
            }
        }

        .wpembed-content {
            order: 2
        }

        .wpembed-madewith {
            margin-top: auto;
            order: 3
        }

        .wpembed-madewith a,
        .wpembed-madewith img {
            display: block;
            width: 200px
        }

        .wpembed-index+h2,
        p+.wpembed-index,
        p+h2,
        ul+h2 {
            padding-top: .5em
        }

        .wpembed-nav {
            order: 1;
            position: relative
        }

        .wpembed-list {
            order: 1
        }

        .wpembed-list h3 {
            margin-bottom: .5em
        }

        .wpembed-bars {
            margin-right: .75em;
            transition: .2s;
            width: 1.5em
        }

        .wpembed-bar {
            background: #333;
            display: block;
            height: 2px;
            margin: 0 auto;
            transition: .2s;
            width: 100%
        }

        .wpembed-bar+.wpembed-bar {
            margin-top: .3em
        }

        .wpembed-nav.wpembed-active .wpembed-bars {
            transform: rotate(90deg)
        }

        .wpembed-nav.wpembed-active .wpembed-bar {
            width: 50%
        }

        .wpembed-nav-button {
            align-items: center;
            display: inline-flex;
            line-height: 1.5;
            padding: 0
        }

        .wpembed-nav ul {
            background: #fff;
            border: 1px solid #ccc;
            left: -1.5em;
            opacity: 0;
            padding: 1.5em 1.75em;
            position: absolute;
            top: 2em;
            visibility: hidden;
            transition: .1s linear
        }

        .wpembed-nav.wpembed-active ul {
            opacity: 1;
            visibility: visible
        }

        .wpembed-nav li {
            margin-left: 1em
        }
    </style>
    <link rel="icon" href="https://app.websitepolicies.com/icon.svg">
    <link rel="apple-touch-icon" href="https://app.websitepolicies.com/apple-touch-icon.png">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&amp;display=swap">
    <style>
        body {
            font-family: Roboto, sans-serif;
        }
    </style>
    <script>window.dataLayer = window.dataLayer || [];</script>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms and conditions for mazayaoffers.ae</title>
    <meta name="description" content="Terms and conditions for mazayaoffers.ae">
    <meta name="robots" content="noindex,follow">
    <meta property="article:published_time" content="2023-11-10T02:51:02+00:00">
    <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-V89YHJB9J5"></script>
    <script>window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-V89YHJB9J5');</script>
</head>

    <body>
        <div id="video-container">

            <img src="../../../../../assets/images/backgrounds/login_background.jpg">
            <!-- <section class="container"> -->
                  
         
                </div> 
                <div class="wpembed-wrapper">
                      <div id="header-logo" (click)="goToLogin()">
                        <img id="logo" src="../../../../../assets/images/mazayaPlus/logo.svg" alt="Logo">
                    </div>
                    <div class="wpembed-container">
                        <iframe  height="800" src="https://mazaya-privacy-policy.s3.ap-south-1.amazonaws.com/Mazaya+terms+and+conditions.html"></iframe>
                    </div>
                </div>
    </body>

    </html>

<!-- </div> -->
