import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-termsand-conditions',
  templateUrl: './termsand-conditions.component.html',
  styleUrls: ['./termsand-conditions.component.scss']
})
export class TermsandConditionsComponent {
  constructor(
    private router: Router,

  ) { }

goToLogin(){
  this.router.navigate(['/login']);
}
}
